export default function (httpClient) {

  return {
    getNodePosts(nodeId, query) {
      return httpClient.get(`/1/communication/nodes/${nodeId}/posts`, query);
    },

    getNodePostsForPerson(nodeId, personId, query) {
      return httpClient.get(`/1/communication/people/${personId}/nodes/${nodeId}/posts`, query);
    },

    getPost(postId, query) {
      return httpClient.get(`/1/communication/posts/${postId}`, query);
    },

    updatePost(postId, postData) {
      return httpClient.put(`/1/communication/posts/${postId}`, postData);
    },

    deletePost(postId) {
      return httpClient.delete(`/1/communication/posts/${postId}`);
    },

    getThreadPosts(threadId, personId, query) {
      return httpClient.get(`/1/communication/people/${personId}/threads/${threadId}/posts`, query);
    },

    createThreadPost(threadId, personId, postData) {
      return httpClient.post(`/1/communication/people/${personId}/threads/${threadId}/posts`, postData);
    }

  }

}